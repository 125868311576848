<template>
  <header-page></header-page>
  <main>
    <section class="companies">
      <div class="companies__container">
        <div class="companies__content">
          <SideBar :isLoading="isLoading"></SideBar>
          <div v-show="!isLoading" class="profile__content">
            <div class="profile__content_left">
              <div class="block_img">
                <img v-if="user.image" :src='this.$store.state.base_directory + "company/"+ user.id + "/"+ user.image'
                     alt="">
                <div class="image_letter"
                     :style="{ background: getBackgroundColor(user.name) }"
                     v-else>{{ user.name.substr(0, 1) }}
                </div>
                <div class="image__change">
                  <input type="file" id="input__file" ref="imageInput" accept="image/*" @change="handleImageChange"/>
                  <label for="input__file">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <g clip-path="url(#clip0_2427_101958)">
                        <path
                            d="M2 2.66667V0.666672H3.33333V2.66667H5.33333V4.00001H3.33333V6.00001H2V4.00001H0V2.66667H2ZM4 6.66667V4.66667H6V2.66667H10.6667L11.8867 4.00001H14C14.7333 4.00001 15.3333 4.60001 15.3333 5.33334V13.3333C15.3333 14.0667 14.7333 14.6667 14 14.6667H3.33333C2.6 14.6667 2 14.0667 2 13.3333V6.66667H4ZM8.66667 12.6667C10.5067 12.6667 12 11.1733 12 9.33334C12 7.49334 10.5067 6.00001 8.66667 6.00001C6.82667 6.00001 5.33333 7.49334 5.33333 9.33334C5.33333 11.1733 6.82667 12.6667 8.66667 12.6667ZM6.53333 9.33334C6.53333 10.5133 7.48667 11.4667 8.66667 11.4667C9.84667 11.4667 10.8 10.5133 10.8 9.33334C10.8 8.15334 9.84667 7.20001 8.66667 7.20001C7.48667 7.20001 6.53333 8.15334 6.53333 9.33334Z"
                            fill="white"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_2427_101958">
                          <rect width="16" height="16" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </label>
                </div>
              </div>
              <div class="block__info">
                <div class="name">{{ user.name }} {{ user.last_name }}</div>
                <div class="position">{{ user.position }}</div>
                <div class="mail">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                        d="M13.332 2.66666H2.66536C1.93203 2.66666 1.3387 3.26666 1.3387 4L1.33203 12C1.33203 12.7333 1.93203 13.3333 2.66536 13.3333H13.332C14.0654 13.3333 14.6654 12.7333 14.6654 12V4C14.6654 3.26666 14.0654 2.66666 13.332 2.66666ZM13.332 12H2.66536V5.33333L7.9987 8.66666L13.332 5.33333V12ZM7.9987 7.33333L2.66536 4H13.332L7.9987 7.33333Z"
                        fill="#888888"/>
                  </svg>
                  <div class="text">{{ user.email }}</div>
                </div>
              </div>

            </div>
            <div class="profile__content_right">
              <div class="picker-option">
                <template v-for="status in arr_status">
                  <button :class="[status.status_name,{'active': real_filters.status_id == status.id}]"
                          @click="changeStatus(status.id)" type="button">
                    {{ status.status_name }}
                  </button>
                  <div class="arrow__top"></div>
                </template>
              </div>
              <div class="wrapper__form" v-if="real_filters.status_id == 1">
                <form action="">
                  <div class="title">CONTACT INFORMATION</div>
                  <div class="inputs__wrapper">
                    <div class="input__wrapper">
                      <div class="text">First name</div>
                      <Input-text :placeholder="'First name'" :text="contact_data.name"
                                  @update:text="contact_data.name = $event"></Input-text>
                    </div>
                    <div class="input__wrapper">
                      <div class="text">Surname</div>
                      <Input-text :placeholder="'Surname'" :text="contact_data.last_name"
                                  @update:text="contact_data.last_name = $event"></Input-text>
                    </div>
                    <div class="input__wrapper">
                      <div class="text">Phone number</div>
                      <Input-text :placeholder="'Phone number'" :text="contact_data.phone"
                                  @update:text="contact_data.phone = $event"></Input-text>
                    </div>
                    <div class="input__wrapper">
                      <div class="text">Email</div>
                      <Input-email :placeholder="'Email'" :email="contact_data.email"
                                   @update:email="contact_data.email = $event"></Input-email>
                    </div>
                  </div>
                  <div class="btn">
                    <Button type="submit" class="white max-content" @click="updateUser">Update info</Button>
                  </div>
                </form>
                <div class="line"></div>
                <form action="" class="password_wrapper">
                  <div class="title">PASSWORD INFORMATION</div>
                  <div class="inputs__wrapper">
                    <div class="input__wrapper">
                      <div class="text">Current password</div>
                      <Input-password :class="{ error: password_errors.currentPassword}"
                                      :placeholder="'Current password'"
                                      :password="currentPassword"
                                      @update:password="currentPassword = $event">
                      </Input-password>
                      <div class="errors_text" v-if="password_errors.currentPassword">Incorrect password</div>
                    </div>
                    <div class="input__wrapper">
                      <div class="text">New password</div>
                      <Input-password :placeholder="'New password'"
                                      :password="newPassword" @update:password="newPassword = $event"></Input-password>
                    </div>
                    <div class="input__wrapper">
                      <div class="text">Confirm password</div>
                      <Input-password :class="{ error: password_errors.passwordMismatch}"
                                      :placeholder="'Confirm password'" :password="confirmPassword"
                                      @update:password="confirmPassword = $event"></Input-password>
                      <div class="errors_text" v-if="password_errors.passwordMismatch">Passwords do not match</div>
                    </div>
                  </div>
                  <p v-if="passwordStatus">{{ passwordStatus }}</p>
                  <div class="btn">
                    <Button type="submit" class="white max-content" @click="changePassword">Update info</Button>
                  </div>
                </form>
              </div>
              <div v-else-if="real_filters.status_id == 2">
                <form action="">
                  <div class="title">COMPANY INFORMATION</div>
                  <div class="inputs__wrapper">
                    <div class="input__wrapper">
                      <div class="text">Company name</div>
                      <Input-text :placeholder="'Company name'" v-model:text="company_data.company_name"></Input-text>
                    </div>
                    <div class="input__wrapper">
                      <div class="text">Full name</div>
                      <Input-text :placeholder="'Full name'" v-model:text="company_data.seo_name"></Input-text>
                    </div>
                    <div class="input__wrapper">
                      <div class="text">Country of registration</div>
                      <!--                      <Input-text :placeholder="'Country'" v-model:text="company_data.country"></Input-text>-->
                      <Select class="h-40 p-reletive" v-model="company_data.country_id" :placeholder_title="'Country'"
                              :data_name="'name'"
                              :sort="countryData" 
                              @search="getSearchCountry"></Select>
                    </div>
                    <div class="input__wrapper">
                      <div class="text">Legal address</div>
                      <Input-text :placeholder="'Address'" v-model:text="company_data.address"></Input-text>
                    </div>
                    <div class="input__wrapper">
                      <div class="text">Postcode</div>
                      <Input-text :placeholder="'Postcode'" v-model:text="company_data.postcode"></Input-text>
                    </div>
                    <div class="input__wrapper opacity">
                      <div class="text">Terms</div>
                      <Input-text :placeholder="'Terms'" :text.sync="company_data.terms+'%'"
                                  :disabled="true"></Input-text>
                    </div>
                  </div>
                  <div class="btn">
                    <Button type="submit" class="white max-content" @click="updateCompany">Update info</Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <PreloaderProfile v-show="isLoading"></PreloaderProfile>
          <modal-dialog v-model:show="dialogs.SuccessfullyDataDialog" :modal="true">
            <div>
              <div class="title__successfully">Successfully</div>
              <div class="text__successfully">Data has been saved</div>
            </div>
            <button class="exit-dialog" @click="this.dialogs.SuccessfullyDataDialog = false">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z"
                      fill="#292929"/>
              </svg>
            </button>
          </modal-dialog>
          <modal-dialog v-model:show="dialogs.SuccessfullyPasswordDialog" :modal="true">
            <div>
              <div class="title__successfully">Successfully</div>
              <div class="text__successfully">The password has been successfully changed</div>
            </div>
            <button class="exit-dialog" @click="this.dialogs.SuccessfullyPasswordDialog = false">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z"
                      fill="#292929"/>
              </svg>
            </button>
          </modal-dialog>
          <modal-dialog v-model:show="dialogs.ErrorDataDialog" :modal="true">
            <div>
              <div class="title__successfully">Something went wrong</div>
              <div class="text__successfully">Data was not saved</div>
            </div>
            <button class="exit-dialog" @click="this.dialogs.ErrorDataDialog = false">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M18.3537 6.70711C18.7442 6.31658 18.7442 5.68342 18.3537 5.29289C17.9632 4.90237 17.33 4.90237 16.9395 5.29289L11.8229 10.4095L6.7076 5.29426C6.31707 4.90373 5.68391 4.90373 5.29338 5.29426C4.90286 5.68478 4.90286 6.31795 5.29338 6.70847L10.4086 11.8237L5.29338 16.939C4.90286 17.3295 4.90286 17.9627 5.29338 18.3532C5.68391 18.7437 6.31707 18.7437 6.70759 18.3532L11.8229 13.2379L16.9395 18.3546C17.33 18.7451 17.9632 18.7451 18.3537 18.3546C18.7442 17.964 18.7442 17.3309 18.3537 16.9404L13.2371 11.8237L18.3537 6.70711Z"
                      fill="#292929"/>
              </svg>
            </button>
          </modal-dialog>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import InputText from "@/components/UI/InputText.vue";
import InputEmail from "@/components/UI/InputEmail.vue";
import InputPassword from "@/components/UI/InputPassword.vue";
import axios from "axios";
import store from "@/store";
import SideBar from "@/components/Busines/SideBar.vue";
import HeaderBusines from "@/components/Busines/HeaderBusines.vue";
import PreloaderProfile from "@/components/PreloaderProfile.vue";
export default {
  components: {SideBar, InputPassword, InputEmail, InputText,HeaderBusines,PreloaderProfile},
  data() {
    return {
      isLoading: true,
      myData: null,
      selectedImage: null,
      contact_data: {
        name: this.$store.state.user.name,
        last_name: this.$store.state.user.last_name,
        phone: this.$store.state.user.person_phone,
        email: this.$store.state.user.person_email,
      },
      countryData: [],
      company_data: {
        country_id: this.$store.state.user.country_id,
        company_name: this.$store.state.user.company_name,
        address:this.$store.state.user.legal_address,
        postcode:this.$store.state.user.postcode,
        seo_name: this.$store.state.user.employee_name,
        terms: this.$store.state.user.terms,
      },
      dialogs: {
        SuccessfullyDataDialog: false,
        ErrorDataDialog: false,
        SuccessfullyPasswordDialog: false,
      },
      password_errors: {
        currentPassword: false,
        passwordMismatch: false,
      },
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      passwordStatus: '',
      arr_status: [
        {"id": 1, "status_name": "Personal Information"},
        {"id": 2, "status_name": "Company Information"},
      ],
      real_filters: {
        'status_id': 1,
      },
    }
  },
  methods: {
    getBackgroundColor(name) {
      // Простейшая хэш-функция для генерации цвета на основе имени
      let hash = 0;
      for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
      }
      let color = '#';
      for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
      }
      return color;
    },
    changeStatus(e) {
      this.real_filters.status_id = e;
    },
    handleImageChange(event) {
      this.selectedImage = event.target.files[0];
      this.uploadImage();
    },
    uploadImage() {
      const formData = new FormData();
      formData.append('id', this.user.id);
      formData.append('image', this.selectedImage);
      this.uploadImageToServer(formData);
    },
    getSearchCountry(e) {
      let fData = new FormData();
      fData.append("name", e);
      axios.post("/busines/register/get-country", fData).then(resp => {
        if (resp.data['result'] == true) {
          this.countryData = resp.data.country;
        }
      }).catch(() => {
      }).finally(() => {
      });
    },
    async uploadImageToServer(formData) {
      try {
        const response = await axios.post('/business/upload-image', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        this.user.image = response.data.image;
      } catch (error) {
        console.error(error);
      }
    },
    async updateUser(e) {
      e.preventDefault();
      try {
        const response = await axios.post('/business/update-user', this.contact_data);
        store.commit('setUser', response.data.data)
        this.dialogs.SuccessfullyDataDialog = true;
      } catch (error) {
        this.dialogs.ErrorDataDialog = true;
      }
    },
    async updateCompany(e) {
      e.preventDefault();
      try {
        const response = await axios.post('/business/update-company', this.company_data);
        store.commit('setUser', response.data.data)
        this.dialogs.SuccessfullyDataDialog = true;
      } catch (error) {
        this.dialogs.ErrorDataDialog = true;
      }
    },
    async changePassword(e) {
      e.preventDefault();
      if (this.newPassword !== this.confirmPassword) {
        this.password_errors.passwordMismatch = true;
        return;
      }


      try {
        const formData = new FormData();
        formData.append('current_password', this.currentPassword);
        formData.append('password', this.newPassword);
        const response = await axios.post('/business/change-password', formData);
        if (response.data.return == true) {
          this.dialogs.SuccessfullyPasswordDialog = true;
          this.password_errors.currentPassword = false;
          this.password_errors.passwordMismatch = false;
        } else {
          this.password_errors.currentPassword = true;
        }

      } catch (error) {
        this.password_errors.currentPassword = true;
      }
    },
    async loadData() {
      this.isLoading = true;
      await Promise.all([
        this.getSearchCountry('')
      ]);
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    },
  },
  mounted() {
    this.loadData();
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  }

}
</script>
<style scoped>
main{
  margin-bottom: 20px;
}
.picker-option {
  max-width: unset;
  margin-bottom: 50px;
}

.input__select input {
  height: 40px;
}

.input__wrapper.opacity {
  opacity: 0.5;
}

.picker-option button {
  width: 100%;
}

.errors_text {
  color: var(--red-100, #F72314);
  font-family: Standerd;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  padding-top: 8px;
}

.title__successfully {
  color: var(--green-400, #39766A);
  font-family: Standerd;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  padding-bottom: 32px;
}

.text__successfully {
  color: var(--gray-400, #606060);
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 25.2px */
}

.profile__content {
  display: flex;
  gap: 24px;
  width: 100%;
}

.profile__content .profile__content_left {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: calc(20.5% - 12px);
  min-width: 220px;
  border-radius: 16px;
  border: 1.5px solid var(--gray-stroke, #E5E5EA);
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.profile__content_left .block_img {
  position: relative;
  margin-bottom: 32px;
}

.profile__content_left .block_img img {
  width: 92px;
  border-radius: 92px;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.profile__content_left .block_img .image_letter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 92px;
  border-radius: 92px;
  aspect-ratio: 1/1;
  object-fit: cover;

  color: #FFF;
  text-align: center;
  font-family: Standerd;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.profile__content_left .image__change input {
  display: none;
}

.profile__content_left .image__change label {
  position: absolute;
  border-radius: 100px;
  border: 2px solid #FFF;
  background: #292929;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.profile__content_left .block__info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile__content_left .block__info .name {
  color: var(--black-400, #292929);
  font-family: Standerd;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 27px */
  letter-spacing: -0.396px;
  text-wrap: nowrap;
}

.profile__content_left .block__info .position {
  color: var(--gray-300, #888);
  font-family: Standerd;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 19.5px */
  letter-spacing: -0.286px;
  text-transform: uppercase;
  padding-bottom: 12px;
}

.profile__content_left .block__info .mail {
  display: flex;
  gap: 4px;
  align-items: center;
  color: var(--gray-300, #888);
  font-family: Standerd;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 19.5px */
  letter-spacing: -0.286px;
}

.profile__content .profile__content_right {
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: calc(79.5% - 12px);
  border-radius: 12px;
  border: 1.5px solid #E5E5EA;
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.wrapper__form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.profile__content_right .line {
  width: 100%;
  height: 1px;
  background: #E5E5EA;
}

.profile__content_right .title {
  color: var(--black-400, #292929);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  letter-spacing: -0.352px;
  padding-bottom: 16px;
}

.profile__content_right .inputs__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 24px;
}

.profile__content_right .input__wrapper {
  flex-grow: 1;
  flex-basis: calc(50% - 12px);
}

.profile__content_right .password_wrapper .input__wrapper:first-of-type {
  flex-grow: 1;
  flex-basis: 100%;
}

.profile__content_right .input__wrapper .text {
  color: var(--black-400, #292929);
  font-family: Standerd;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 123.077% */
  padding-bottom: 8px;

}

@media screen and (max-width: 1024px) {
  .profile__content .profile__content_right {
    margin-bottom: 0 !important;
  }

  .profile__content {
    flex-direction: column;
    gap: 32px;
  }

  .profile__content .profile__content_left {
    width: 100%;
    padding: unset;
    border-radius: unset;
    border: unset;
    background: unset;
    box-shadow: unset;
  }

  .profile__content .profile__content_right {
    width: 100%;
    padding: unset;
    border-radius: unset;
    border: unset;
    background: unset;
    box-shadow: unset;
    margin-bottom: 142px;
  }

  .profile__content_right .title {
    padding-bottom: 24px;
  }

  .profile__content_right .input__wrapper {
    flex-basis: 100%;
  }

  .profile__content_right .line {
    background: unset;
    height: 0;
  }

  .btn button.max-content {
    width: 100%;
  }
  main{
  margin-bottom: 119px;
}
}
</style>