import {createApp} from 'vue'
import App from './App.vue'
import components from '@/components/UI'
import router from "@/router";
import axios from "axios";
import store from "@/store";
import Maska from 'maska';
import ClickOutside  from  '@/assets/ClickOutsideDirective.js' ;

if (window.localStorage.getItem("auth_token")) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.localStorage.getItem("auth_token");
}
if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = "http://localhost/api";
    store.state.base_directory = "http://localhost/storage/";
} else {
    axios.defaults.baseURL = "/api";
    store.state.base_directory = "/storage/";
}
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';

const app = createApp(App);
components.forEach(component => {
    app.component(component.name, component);
})
app.use(router)
    .use(store)
    .use(Maska)
    .directive ( 'click-outside' , ClickOutside )
    .mount('#app')