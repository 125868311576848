<template>
    <div class="block__values">
        <div class="title">General information</div>
        <div class="block_value">
            <div class="text_value">Estimated duration</div>
            <div class="value">{{ estimated_duration }} {{
                estimated_duration_type == 1 ? 'Minutes' 
                : estimated_duration_type == 2 ? 'Hour' 
                : estimated_duration_type == 3 ? 'Days'
                : estimated_duration_type == 4 ? 'Weeks'
                : false }}</div>
        </div>
        <div class="block_value">
            <div class="text_value">Pickup</div>
            <div class="value">{{ meeting_point_type == 1 ? 'Offered' : 'No' }}</div>
        </div>
        <div class="block_value">
            <div class="text_value">Price</div>
            <div class="value"> {{ price != null ? symbol+price: 'Free' }}</div>
        </div>
        <div class="block_value">
            <div class="text_value">Dates of the event</div>
            <div class="value" v-if="start_date == end_date">{{ start_date }}</div>
        </div>
        <div class="block_value">
            <div class="text_value">Live tour guide</div>
            <div class="value">{{ languages_name }}</div>
        </div>
        <div class="block_value">
            <div class="text_value">{{ max_participants < 8 ? 'Small Group' : 'Big Group' }}</div>
                <div class="value">Limited to {{ max_participants }} participants
                </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        estimated_duration: String,Number,
        estimated_duration_type: String,Number,
        meeting_point_type: String,Number,
        max_participants: String,Number,
        languages_name: String,
        price: String,
        symbol: String,
        start_date: String,
        end_date: String,
    },
}
</script>

<style scoped>
.block__values .title {
    color: #000;
    font-family: Standerd;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
    padding-bottom: 16px;
}

.block__values .block_value {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    border-top: 1px solid #E5E5EA;
    ;
}

.block__values .block_value:last-of-type {
    padding-bottom: 0;
}

.block__values .text_value {
    color: var(--black-400, #292929);
    font-family: Standerd;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    /* 25.2px */
    letter-spacing: 0.108px;
}

.block__values .value {
    color: var(--black-400, #292929);
    text-align: right;
    font-family: Standerd;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 25.2px */
    letter-spacing: 0.108px;
    display: flex;
    align-items: center;
}
</style>