<template>
    <input type="text" @input="this.$emit('update:modelValue', $event.target.value)" :placeholder="placeholder" :class="{'is-invalid': isInvalid}">
</template>

<script>
    export default {
        name: 'input-text',
        props: {
            placeholder: String,
            isInvalid: Boolean,
        },
    }
</script>

<style scoped>
input{
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF); 

    color: var(--Gray-300, #888);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */

    padding-left: 12px;
    height: 50px;
    width: 100%;
    outline: none;
}
.is-invalid {
  border: 1.5px solid red !important;
}
</style>