<template>
<div><slot></slot></div>
</template>

<script>
    export default {
        name: 'error-field-text'
    }
</script>

<style scoped>
div {
  color: var(--Red-100, #F72314);
  font-family: Standerd;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 8px;
  /* 133.333% */
}
</style>