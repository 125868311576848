<template>
    <div class="radio-wrapper">
            <label :for="name+'-'+item.value" v-for="item in array">
            {{item.title}}
            <input type="checkbox" v-model="selectedItems" @change="handleCheckboxChange" :id="name+'-'+item.value" :name="name" :value="item.value"/>
            <span></span>
        </label>
    </div>
</template>

<script>
export default {
    props: {
        array: Array,
        selected: Array,
    },
    data() {
        return {
            selectedItems: [],
        }
    },
    methods: {
        handleCheckboxChange() {
            this.$emit('update:modelValue', this.selectedItems);
        }
    },
    watch: {
        selected() {
            if(this.selected != null){
            this.selectedItems = [...this.selected];
            this.$emit('update:modelValue', this.selectedItems);
            }
        },
    }
}
</script>

<style scoped>
.is-invalid {
  border: 1.5px solid red !important;
}
.radio-wrapper{
    display: flex;    
    gap: 20px;
    flex-wrap: wrap;
}
.radio-wrapper.choose-time{
    gap: 16px;
}
.radio-wrapper.choose-time label{
    max-width: 182px;
}
.radio-wrapper label{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    min-width: 100px;
    max-width: 200px;
    width: 100%;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
    height: 50px;
    padding: 0 16px;

    color: var(--Black-300, #333);
    font-family: Standerd;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.102px;
    white-space: nowrap;
    cursor: pointer;
}
.radio-wrapper label input{
    display: none;
}
.radio-wrapper span{
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    border: 1.5px solid #CCCCCC;
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    position: relative;
}
.radio-wrapper span::after{
    position: absolute;
    content: '';

    background-color: #39766A;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    min-width: 10px;
    min-height: 10px;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform:  translate(-50%, -50%);
    opacity: 0;
    transition: 0.3s;
}
.radio-wrapper input[type="checkbox"]:checked + span::after {
    opacity: 1;
}
@media screen and (max-width: 768px) {
    .radio-wrapper{
        gap: 16px;
    }
    .radio-wrapper.w-100 label{
        min-width: unset;
        max-width: unset;
        width: 100%;
    }
    .radio-wrapper label{
        min-width: unset;
        max-width: unset;
        width: calc((100% /2) - 8px);
    }
}

</style>