<template>
    <div class="wrapper">
        <div class="items-list">
            <div class="item" v-for="(item, index) in list" :key="index">
                
                <LocationWrapperBox v-if="type == 2">
                    <InputTextCountry
                        class="half-width" 
                        :placeholder="'Country'" 
                        v-model="item.country"
                        :value="item.country" >
                    </InputTextCountry>
                    <InputTextCity
                            class="half-width" 
                            :placeholder="'City'" 
                            :country="item.country"
                            :value="getPrevCity(index)"
                            :country_value="getPrevCountry(index)"
                            v-model="item.city">
                    </InputTextCity>
                </LocationWrapperBox>
                <!-- <InputTextAddressList v-model="list[index]" 
                :placeholder="'Text'"  
                @show="show = $event" 
                :value="item.value" 
                :show="show" 
                :city="item.id" 
                @city="city = $event" 
                @city_id="city_id = $event" 
                v-if="type == 2"></InputTextAddressList> -->
                <InputText v-model="item.value" :value="item.value" :placeholder="'Text'" v-else></InputText>   
                
                <span @click="removeRow(index)" v-if="index > 0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M15.4127 5.70711C15.8032 5.31658 15.8032 4.68342 15.4127 4.29289C15.0222 3.90237 14.389 3.90237 13.9985 4.29289L9.85246 8.43889L5.7076 4.29403C5.31707 3.90351 4.68391 3.90351 4.29338 4.29403C3.90286 4.68455 3.90286 5.31772 4.29338 5.70824L8.43825 9.85311L4.29338 13.998C3.90286 14.3885 3.90286 15.0217 4.29338 15.4122C4.68391 15.8027 5.31707 15.8027 5.70759 15.4122L9.85246 11.2673L13.9985 15.4133C14.389 15.8039 15.0222 15.8039 15.4127 15.4133C15.8032 15.0228 15.8032 14.3896 15.4127 13.9991L11.2667 9.85311L15.4127 5.70711Z"
                            fill="#606060" />
                    </svg>
                </span>
            </div>
        </div>
        <div class="add-row" @click="addRow">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                    d="M13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5V11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H11V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H13V5Z"
                    fill="#292929" />
            </svg>
        </div>
    </div>
</template>

<script>
import InputTextAddressList from "@/components/Busines/EventDraft/InputTextAddressList.vue";
import InputText from "@/components/Busines/EventDraft/InputText.vue";
import InputTextCountry from "@/components/Busines/EventDraft/InputTextCountry.vue";
import InputTextCity from "@/components/Busines/EventDraft/InputTextCity.vue";
import LocationWrapperBox from "@/components/Busines/NewEvent/LocationWrapperBox.vue";
export default {
    components: {
        LocationWrapperBox,
        InputTextCity,
        InputTextCountry,
        InputTextAddressList,
        InputText
    },
    name: 'add-list',
    props: {
        type: Number,
        value: Array,
    },
    data() {
        return {
            list: [
                { value: ''},
            ],
            prev_list: [],
            show: true,
        }
    },
    mounted () {
        ;
    },
    methods: {
        getPrevCountry(index) {
      return this.prev_list[index] ? this.prev_list[index].country : 'N/A';
    },
    getPrevCity(index) {
      return this.prev_list[index] ? this.prev_list[index].city : 'N/A';
    },
        addRow() {
            this.list.push({ value: ''});
        },
        removeRow(index) {
            if (this.list.length > 0) {
                this.list.splice(index, 1)
            }
        }
    },
    watch: {
        list: {
            handler(val, oldVal) {
                this.$emit('update:modelValue', this.list);
            },
            deep: true
        },
        value: {
            handler(newData) {
                if(newData && newData.length > 0) {
                    this.list = [...newData];
                    this.prev_list = [...newData];
                    console.log('22222');
                }
            },
            deep: true,
        },
    },
}
</script>

<style scoped>
.half-width {
    width: calc(48% - 16px); /* элементы занимают половину ширины контейнера с отступами */
}
.full-width {
    flex: calc(69% - 16px); /* элемент занимает всю ширину контейнера */
}
.low-width{
    flex: calc(31% - 16px);
}
.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    width: 100%;
}

.items-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}

.items-list .item {
    position: relative;
    max-width: 764px;
}

.items-list .item span {
    position: absolute;
    right: 15px;
    top: 15px;
}

.add-row {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);

    /* last */
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    width: 40px;
    height: 40px;
}</style>