<template>
    <div class="wrapper">
        <div class="content-wrapper">
            <div class="custom-control" v-for="event in category" :key="event.id">
                <input type="checkbox" v-model="selectedItems" @change="handleCheckboxChange" :id="event.id" :name="name"
                    class="custom-control-input" :value="event.id" />
                <label class="custom-control-label" :for="event.id" :class="{'is-invalid': isInvalid}">
                    <img :src="require('@/assets/image/categories/' + event.name + '.svg')" alt="">
                    {{ event.name }}
                </label>
            </div>
            <div class="custom-control" :class="{ 'active': otherIndex }" @click="otherIndex = !otherIndex" >
                <input type="checkbox" class="custom-control-input" />
                <label class="custom-control-label" :class="{'is-invalid': isInvalid}">
                    <img :src="require('@/assets/image/categories/Other.svg')" alt="">
                    Other
                </label>
            </div>
        </div>
        <div class="activity-wrapper" v-if="otherIndex">
            <input class="input-style-none" @focus="showDropDown = true" @blur="handleBlur"  v-model="valueOtherCategory" type="text" id="activities-take"
                :placeholder="'Specify your category'">

            <div class="drop-down" v-if="showDropDown">
                <div v-for="(activity, index) in categorySearch" :key="activity.id"
                    @click="handleCheckboxOther(index); showDropDown = false;">
                    {{ activity.name }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'category-select',
    props: {
        name: String,
        category: Object,Array,
        categorySearch: Object,Array,
        isInvalid: Boolean,
    },
    data() {
        return {
            showDropDown: false,
            selectedItems: [],
            valueOtherCategory: '',
            otherIndex: false,
        }
    },
    methods: {
        handleBlur() {
            // Introduce a small delay before hiding the drop-down
            setTimeout(() => {
                if (!this.$el.contains(document.activeElement)) {
                    this.showDropDown = false;
                }
            }, 100);
        },
        handleCheckboxOther(index) {
            if (this.selectedItems.length >= 3) {
                this.otherIndex = false;
                return false;
            } else {
                const categoryId = this.categorySearch[index].id;
                const isCategoryExists = this.category.some(cat => cat.id === categoryId);
                this.selectedItems.push(categoryId);
                this.$emit('update:modelValue', this.selectedItems); 
                if (!isCategoryExists) {
                    this.$emit('otherCategory', this.categorySearch[index]);
                }

                this.otherIndex = false;
            }
        },
        handleCheckboxChange() {
            if (this.selectedItems.length > 3) {
                this.selectedItems.pop();
            }
            this.$emit('update:modelValue', this.selectedItems);
        }
    },
    watch: {
        categorySearch: {
            handler(val, oldVal) {
                this.selectedItems = [];
                this.$emit('update:modelValue', this.selectedItems);
            },
            deep: true
        },
    },
}
</script>

<style scoped>
.is-invalid {
  border: 1.5px solid red !important;
}
.content-wrapper {
    display: flex;
    gap: 16px;
    max-width: 764px;
    flex-wrap: wrap;
}

.custom-control label {
    width: 179px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    padding: 28px 35px;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);


    color: var(--Black-400, #292929);
    text-align: center;
    font-family: Standerd;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    /* 17px */
    letter-spacing: -0.374px;
    cursor: pointer;
}

.custom-control-input {
    display: none;
}

.custom-control-input:checked~.custom-control-label,
.custom-control.active .custom-control-label {
    border-radius: 8px;
    border: 1.5px solid var(--Green-400, #39766A);
    background: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.wrapper>input {
    margin-top: 16px;
    padding-left: 12px;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    height: 50px;
    width: 100%;

    color: var(--Gray-300, #888);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */

    outline: none;
}

.wrapper.error>input {
    border-radius: 8px;
    border: 1.5px solid var(--Red-200, #EA2313);
}

.activities-take {
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    padding-left: 16px;
    width: 100%;
    height: 36px;
    display: flex;
    gap: 10px;
    align-items: center;
    padding-left: 12px;
    height: 50px;
    max-width: 764px;
}

.activity-wrapper {
    position: relative;
    margin-top: 16px;
}

.activity-wrapper .drop-down {
    position: absolute;
    top: 113%;
    left: 0;
    width: 100%;
    overflow-y: auto;
    max-height: 200px;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: #FFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
    padding: 8px;
}

.activity-wrapper .drop-down div {
    padding: 0 8px;
    height: 40px;
    display: flex;
    align-items: center;

    color: #232323;
    font-family: Standerd;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 133.333% */
    cursor: pointer;
}

.activities-take.error {
    border-radius: 8px;
    border: 1.5px solid var(--Red-200, #EA2313);
}

.input-style-none {
    border-radius: unset;
    border: unset;
    padding-left: unset;
    height: unset;
}

.activity-wrapper input {
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    padding-left: 16px;
    width: 100%;
    height: 50px;
    color: var(--Gray-300, #888);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: 0.084px;
    outline: none;
}

@media screen and (max-width: 768px) {
    .custom-control {
        width: calc((100% /2) - 8px);
    }

    .custom-control label {
        min-width: unset;
        max-width: unset;
        width: 100%;
    }
}
</style>