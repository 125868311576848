<template>
    <div class="block__description">
        <div class="title">{{ title }}</div>
        <div class="text" :class="{ full: readMore}">{{ value }}</div>
        <div class="read_more" v-if="!readMore && more" @click="readMore = !readMore">Read more</div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        value: String,
        more: {
            Boolean,
            default: true,
        }
    },
    data() {
        return {
            readMore: false,
        }
    },
}
</script>

<style scoped>

.block__description .title {
    color: #292929;
    font-family: Standerd;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
    padding-bottom: 16px;
}

.block__description .text {
    color: var(--black-400, #292929);
    font-family: Standerd;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 23.8px */
    letter-spacing: 0.102px;
    height: 100%;
    max-height: 48px;
    overflow: hidden;
    transition: all 0.3s;
}
.block__description .text.full {
    max-height: none;
}
.block__description .read_more {
    padding-top: 24px;
    color: #000;
    font-family: Standerd;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.108px;
    text-decoration-line: underline;
}
</style>