<template>
    <div class="activity-wrapper" v-click-outside="() => handleClickOutside()">
        <label class="activities-take" for="activities-take" :class="{'is-invalid': isInvalid}">
            <input class="input-style-none" @click="open()"
                @input="getSearchActivities($event)"
                v-model="selectedActiviti"
                type="text" id="activities-take"
                :placeholder="placeholder">

        </label>
        <div class="drop-down" v-if="showDropDown">
            <div v-for="activity in activitiesData" :key="activity.id" 
            @click="showDropDown = false; selectedActiviti = activity.name; this.$emit('update:modelValue', activity.id)"
            >
                {{ activity.name }}</div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    props: {
        placeholder: String,
        city: String,
        show: Boolean,
        isInvalid: Boolean,
    },
    data() {
        return {
            id: '',
            showDropDown: false,
            selectedActiviti: "",
            activitiesData: [],
        }
    },
    methods: {
        open(){
            this.showDropDown = true;
        },
        handleClickOutside() {
            this.showDropDown = false;
        },
        handleBlur() {
            // Introduce a small delay before hiding the drop-down
            setTimeout(() => {
                if (!this.$el.contains(document.activeElement)) {
                    this.showDropDown = false;
                }
            }, 100);
        },
        getSearchActivities(e) {
            let fData = new FormData();
            let name = e;
            if (name) fData.append("name", e.target.value);
            else fData.append("name", e);
            axios.post("/business/new-event/get-country", fData).then(resp => {
                if (resp.data['result'] == true) {
                    this.activitiesData = resp.data.data;
                }
            }).catch(() => { }).finally(() => { });
        },
    },
    mounted() {
        this.getSearchActivities('');
    },
}
</script>

<style scoped>
.is-invalid {
  border: 1.5px solid red !important;
}
.activities-take {
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    padding-left: 16px;
    width: 100%;
    height: 36px;
    display: flex;
    gap: 10px;
    align-items: center;
    padding-left: 12px;
    height: 50px;
    max-width: 764px;
}

.activity-wrapper {
    position: relative;
}

.activity-wrapper .drop-down {
    position: absolute;
    top: 113%;
    left: 0;
    width: 100%;
    overflow-y: auto;
    max-height: 200px;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: #FFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
    padding: 8px;
    z-index: 10;
}

.activity-wrapper .drop-down div {
    padding: 0 8px;
    height: 40px;
    display: flex;
    align-items: center;

    color: #232323;
    font-family: Standerd;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 133.333% */
}

.activities-take.error {
    border-radius: 8px;
    border: 1.5px solid var(--Red-200, #EA2313);
}

.input-style-none {
    border-radius: unset;
    border: unset;
    padding-left: unset;
    height: unset;
}

input {
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    padding-left: 16px;
    width: 100%;
    height: 36px;
    color: var(--Gray-300, #888);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: 0.084px;
    outline: none;
}
</style>