<template>
    <div class="country-input-wrapper">
      <div class="dropdown" :class="{ active: target }" @focusout="hideDropdown">
        <div class="selected-country" @click="target = !target;" v-for="country in selectCode">

          <div>
          <div class="country-code">({{ country.dial_code }})</div>
          <div class="country-flag">
            {{ country.name }}
          </div>
          </div>
          <svg class="arrow-list" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M13.2372 14.1958C12.8408 14.6282 12.1592 14.6282 11.7628 14.1958L8.53608 10.6757C7.94813 10.0343 8.40313 9 9.27323 9L15.7268 9C16.5969 9 17.0519 10.0343 16.4639 10.6757L13.2372 14.1958Z"
              fill="#5A5E62" />
          </svg>
        </div>
        <div class="dropdown-list" v-if="target">
          <div class="list-item" v-for="country in countries" @click="changeCode(country.code); target = false;">
            
            <div class="code">({{ country.dial_code }})</div>
            <div class="name">{{ country.name }}</div>
          </div>
        </div>
      </div>
      <input class="input-phone" type="text" id="phone" v-model="inputValue" v-maska:[bindedObject] @input="emitInputData"
        :placeholder="bindedObject.mask_placeholder" />
    </div>
  </template>
  
  <script>
  import { vMaska } from "maska"
  export default {
    name: 'VueCountryIntl',
    directives: { maska: vMaska },
    props: {
      countries: Object, Array,
      phone: String,
    },
    data() {
      return {
        bindedObject: {
          mask: "+93-##-###-####",
          completed: false,
        },
        selectCode: {},
        target: false,
        inputValue: "",
        formattedPhoneNumber: "",
      }
    },
    methods: {
      changeCode(value) {
        console.log('click');
        this.selectCode = this.countries.filter(country => country.code === value);
        this.bindedObject.mask = this.selectCode[0].mask;
        this.bindedObject.mask_placeholder = this.selectCode[0].mask_placeholder;
        this.target = false;
      },
      emitInputData() {
        if (this.bindedObject.mask.length == this.inputValue.length) {
          this.$emit('update:modelValue', this.inputValue);
        }
        else this.$emit('update:modelValue', '');
      },
    },
    mounted() {
      this.changeCode(this.countries[0].code);
    },
  }
  </script>
  
  <style scoped>
  .country-input-wrapper.error .input-phone {
    border-radius: 8px;
    border: 1.5px solid var(--Red-200, #EA2313);
  }
  
  .dropdown {
    position: relative;
    display: flex;
    gap: 12px;
    height: 40px;
    padding: 8px;
    padding-left: 10px;
    cursor: pointer;
    border-radius: 8px;
    border: 2px solid var(--Gray-stroke, #E5E5EA);
    max-width: 173px;
    width: 100%;
  }
  
  .dropdown.active {
    border-radius: 8px 8px 0 0;
  }
  
  .selected-country {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--Gray-300, #888);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    overflow: hidden;
    justify-content: space-between;
  }
  .selected-country > div{
    display: flex;
    gap: 4px;
    overflow: hidden;
  }
  .selected-country div > .country-flag{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .selected-country .arrow-list {
    margin-left: 10px;
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
  }
  
  .dropdown-list {
    position: absolute;
    top: 87%;
    left: -1.3px;
    width: calc(100% + 3px);
    background-color: #fff;
    border-radius: 8px;
    border: 2px solid var(--Gray-stroke, #E5E5EA);
    border-top: unset;
    max-height: 150px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 2;
    border-radius: 0px 0px 8px 8px;
  }
  
  .dropdown-list .list-item {
    display: flex;
    cursor: pointer;
    padding: 0 10px;
    gap: 4px;
    color: var(--Gray-300, #888);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */

  }
  .dropdown-list .list-item > .name{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .dropdown-list .list-item:last-child {
    padding-bottom: 10px;
  }
input {
  border-radius: 8px;
  border: 1.5px solid var(--Gray-stroke, #E5E5EA);
  padding-left: 16px;
  width: 100%;
  height: 36px;
  color: var(--Gray-300, #888);
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  outline: none;
}

input:focus {
  border-radius: 8px;
  border: 1.5px solid var(--Gray-300, #888);
  color: #292929;
}

input.error {
  border-radius: 8px;
  border: 1.5px solid var(--Red-200, #EA2313);
}


input::placeholder {
  color: var(--Gray-300, #888);
  font-family: Standerd;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

@media screen and (max-width: 768px) {
  .dropdown{
      max-width: 159px;
  }
  .selected-country .arrow-list{
    margin-left: 10px;
  }
}
  </style>