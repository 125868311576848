<template>
    <textarea name="" id="" @input="this.$emit('update:modelValue', $event.target.value)" cols="30" rows="10"
    :class="{'is-invalid': isInvalid}"
        :placeholder="placeholder"></textarea>
</template>

<script>
export default {
    name: 'input-text-area',
    props: {
        placeholder: String,
        isInvalid: Boolean,
    },
}
</script>

<style scoped>
textarea {
    width: 100%;
    resize: none;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);
    padding: 16px 12px;
    height: 111px;
    outline: none;
    color: var(--Gray-300, #888);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: 0.084px;
}
.is-invalid {
  border: 1.5px solid red !important;
}
@media screen and (max-width: 768px) {
    textarea {
        padding: 12px;
        height: 168px;
    }
}</style>